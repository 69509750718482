import { ROLE_USER } from '../../data/constants'

export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

const role: number = Number(localStorage.getItem('role'))
const roleRoutes = [
  // {
  //   name: 'dashboard',
  //   displayName: 'Dashboard',
  //   meta: {
  //     icon: 'vuestic-iconset-dashboard',
  //   },
  // },
]

switch (role) {
  case 0:
    // admin super
    roleRoutes.push(
      {
        name: 'pengguna',
        displayName: 'Pengguna',
        meta: {
          icon: 'group',
        },
      },
      {
        name: 'pengguna_role',
        displayName: 'Role Pengguna',
        meta: {
          icon: 'badge',
        },
      },
      {
        name: 'log_sistem',
        displayName: 'Log Sistem',
        meta: {
          icon: 'article',
        },
      },
      {
        name: 'pengumuman',
        displayName: 'Pengumuman',
        meta: {
          icon: 'campaign',
        },
      },
      {
        name: 'galeri',
        displayName: 'Galeri',
        meta: {
          icon: 'photo_library',
        },
      },
      {
        name: 'ppkd_master',
        displayName: 'PPKD',
        meta: {
          icon: 'groups',
        },
      },
      {
        name: 'kejuruan',
        displayName: 'Kejuruan',
        meta: {
          icon: 'engineering',
        },
      },
    )
    break
  case 1:
    roleRoutes.push()
    break
  case 2:
    // admin ppkd
    roleRoutes.push(
      {
        name: 'dashboard',
        displayName: 'Dashboard',
        meta: {
          icon: 'vuestic-iconset-dashboard',
        },
      },
      {
        name: 'pelatihan',
        displayName: 'Pelatihan',
        meta: {
          icon: 'mso-note_stack',
        },
      },
      {
        name: 'pengumuman',
        displayName: 'Pengumuman',
        meta: {
          icon: 'campaign',
        },
      },
      {
        name: 'unggah_sertifikat',
        displayName: 'Unggah Sertifikat',
        meta: {
          icon: 'card_membership',
        },
      },
      {
        name: 'penempatan',
        displayName: 'Penempatan',
        meta: {
          icon: 'mso-location_away',
        },
      },
      {
        name: 'pengajar',
        displayName: 'Pengajar',
        meta: {
          icon: 'mso-cast_for_education',
        },
      },
    )
    break
  case 3:
    // peserta
    roleRoutes.push(
      {
        name: 'pelatihan',
        displayName: 'Pelatihan',
        meta: {
          icon: 'mso-note_stack',
        },
      },
      {
        name: 'sertifikat',
        displayName: 'Sertifikat',
        meta: {
          icon: 'contact_emergency',
        },
      },
    )
    break
  case 4:
    // pengajar
    roleRoutes.push(
      {
        name: 'materi',
        displayName: 'Materi',
        meta: {
          icon: 'mso-history_edu',
        },
      },
      {
        name: 'absensi',
        displayName: 'Absensi',
        meta: {
          icon: 'mso-person_check',
        },
      },
      {
        name: 'lpj',
        displayName: 'LPJ',
        meta: {
          icon: 'engineering',
        },
      },
      // {
      //   name: 'absensi',
      //   displayName: 'Absensi',
      //   meta: {
      //     icon: 'event_note',
      //   },
      // },
    )
    break
  case 5:
    // admin dinas
    roleRoutes.push(
      {
        name: 'dashboard',
        displayName: 'Dashboard',
        meta: {
          icon: 'vuestic-iconset-dashboard',
        },
      },
      {
        name: 'pelatihan',
        displayName: 'Pelatihan',
        meta: {
          icon: 'mso-note_stack',
        },
      },
      {
        name: 'pengumuman',
        displayName: 'Pengumuman',
        meta: {
          icon: 'campaign',
        },
      },
    )
    break
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: roleRoutes as INavigationRoute[],
}
