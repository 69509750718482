import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import LandingPageLayout from '../layouts/LandingPageLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'landing',
    component: LandingPageLayout,
    children: [
      {
        name: 'beranda',
        path: '',
        component: () => import('../pages/landing/Index.vue'),
      },
      {
        name: 'daftar-pengumuman',
        path: 'daftar-pengumuman',
        component: () => import('../pages/landing/DaftarPengumumanPage.vue'),
      },
      {
        name: 'deskrip-pengumuman',
        path: 'deskrip-pengumuman',
        component: () => import('../pages/landing/PengumumanPage.vue'),
      },
      {
        name: 'daftar-galeri',
        path: 'galeri',
        component: () => import('../pages/landing/GaleriPage.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },

      // admin super
      {
        name: 'pengguna',
        path: 'pengguna',
        component: () => import('../pages/users/UsersPage.vue'),
      },

      // admin super
      {
        name: 'pengguna_role',
        path: 'pengguna_role',
        component: () => import('../pages/users-role/UsersRolePage.vue'),
      },

      // admin super
      {
        name: 'log_sistem',
        path: 'log_sistem',
        component: () => import('../pages/log/LogPage.vue'),
      },

      // admin super, admin dinas, admin ppkd
      {
        name: 'pengumuman',
        path: 'pengumuman',
        component: () => import('../pages/pengumuman-fe/PengumumanFEPage.vue'),
      },

      // admin super
      {
        name: 'galeri',
        path: 'galeri',
        component: () => import('../pages/galeri/GaleriPage.vue'),
      },

      // admin super
      {
        name: 'ppkd_master',
        path: 'ppkd_master',
        component: () => import('../pages/ppkd/PPKDPage.vue'),
      },

      // admin dinas, admin ppkd, peserta
      {
        name: 'pelatihan',
        path: 'pelatihan',
        component: () => import('../pages/new-pelatihan/PelatihanPage.vue'),
      },

      // admin super
      {
        name: 'kejuruan',
        path: 'kejuruan',
        component: () => import('../pages/kejuruan/KejuruanPage.vue'),
      },

      // admin ppkd
      {
        name: 'unggah_sertifikat',
        path: 'unggah_sertifikat',
        component: () => import('../pages/new-certificate/CertificatePage.vue'),
      },

      // admin ppkd
      {
        name: 'penempatan',
        path: 'penempatan',
        component: () => import('../pages/penempatan/PenempatanPage.vue'),
      },

      // admin ppkd
      {
        name: 'pengajar',
        path: 'pengajar',
        component: () => import('../pages/pengajar/PengajarPage.vue'),
      },

      // admin peserta
      {
        name: 'sertifikat',
        path: 'sertifikat',
        component: () => import('../pages/certificate/CertificateUserPage.vue'),
      },

      // pengajar
      {
        name: 'materi',
        path: '/materi',
        component: () => import('../pages/materials/MateriPage.vue'),
      },

      // pengajar
      {
        name: 'absensi',
        path: '/absensi',
        component: () => import('../pages/new-attendance/AbsensiPage.vue'),
      },

      // pengajar
      {
        name: 'lpj',
        path: '/lpj',
        component: () => import('../pages/lpjk/LPJPage.vue'),
      },
      {
        name: 'tambah_pengumuman',
        path: 'tambah_pengumuman',
        component: () => import('../pages/pengumuman/CreatePengumuman.vue'),
      },
      {
        name: 'edit_pengumuman',
        path: 'edit_pengumuman',
        component: () => import('../pages/pengumuman/CreatePengumuman.vue'),
      },
      {
        name: 'tambah_pelatihan',
        path: 'tambah_pelatihan',
        component: () => import('../pages/pelatihan/CreatePelatihan.vue'),
      },
      {
        name: 'validasi_peserta',
        path: 'validasi_peserta',
        component: () => import('../pages/validasi/ValidasiPeserta.vue'),
      },
      // {
      //   name: 'sertifikat_pengguna',
      //   path: 'sertifikat_pengguna',
      //   component: () => import('../pages/certificate/CertificateUser.vue'),
      // },
      // {
      //   name: 'preview_sertifikat',
      //   path: 'preview_sertifikat/:sertifikat',
      //   component: () => import('../pages/certificate/CertificatePreview.vue'),
      // },
      // {
      //   name: 'projects',
      //   path: 'projects',
      //   component: () => import('../pages/new-projects/ProjectsPage.vue'),
      // },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
      {
        name: 'jadwal',
        path: 'jadwal',
        component: () => import('../pages/pelatihan/JadwalPelatihanPage.vue'),
      },
      // {
      //   name: 'tambah_materi',
      //   path: 'tambah_materi',
      //   component: () => import('../pages/materials/CreateMaterial.vue'),
      // },
      // {
      //   name: 'tambah_lpjk',
      //   path: 'tambah_lpjk',
      //   component: () => import('../pages/lpjk/CreateLPJK.vue'),
      // },
      {
        name: 'peserta',
        path: 'peserta',
        component: () => import('../pages/peserta/PesertaPage.vue'),
      },
      {
        name: 'serapan_kerja',
        path: 'serapan_kerja',
        component: () => import('../pages/peserta/PenempatanPage.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'masuk',
        path: 'masuk',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'daftar',
        path: 'daftar',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'otp',
        path: 'otp',
        component: () => import('../pages/auth/Otp.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
